<template>
    <div class="wrapper has_nav_bar">
        <van-nav-bar title="提交资质" @click-left="goBack" left-text="返回" left-arrow fixed/>
        <div class="content">
            <div class="main-content">

                <div class="submit-qual">
                    <van-form ref="viewForm">

                        <div class="divider has_margin">资质信息</div>
                        <div class="submit-qual-section">
                            <div class="submit-qual-section-block">
                                <div class="headline"><span class="required">*</span>身份证扫描件</div>
                                <div class="tips">要求：正反面身份证件扫描件，支持jpg, png, JPEG格式，单个文件不超过5M</div>
                                <div class="upload">
                                    <van-uploader v-model="uploadIdName" :disabled="disabled" :deletable="deletable"
                                                  :before-delete="beforeIdNameDelete" :before-read="beforeIdNameRead"
                                                  accept="image/*" max-size="5*1024*1024" max-count="2"
                                                  multiple/>
                                </div>
                            </div>

                            <div class="submit-qual-section-block divider-line">
                                <div class="headline"><span class="required">*</span>近期一寸免冠电子证件照片</div>
                                <div class="tips">要求：蓝底或白底一寸免冠电子照片，支持jpg, png, JPEG格式，单个文件不超过5M</div>
                                <div class="upload">
                                    <van-uploader v-model="uploadBareheadedName" :disabled="disabled"
                                                  :before-delete="beforeBareheadedNameDelete"
                                                  :before-read="beforeBareheadedNameRead"
                                                  :deletable="deletable" accept="image/*" max-size="5*1024*1024"
                                                  max-count="1" multiple/>
                                </div>
                            </div>

                            <div class="submit-qual-section-block divider-line">
                                <div class="headline"><span class="required">*</span>所有学历，学位证书扫描件</div>
                                <div class="tips">要求：留学人员需上传教育部中国留学服务中心出具的国（境）外学历学位认证书，支持jpg, png, JPEG格式，单个文件不超过5M
                                </div>
                                <div class="upload">
                                    <van-uploader v-model="uploadCertificateName" :disabled="disabled"
                                                  :before-delete="beforeCertificateNameDelete"
                                                  :before-read="beforeCertificateNameRead"
                                                  :deletable="deletable" accept="image/*" max-size="5*1024*1024"
                                                  max-count="5" multiple/>
                                </div>
                            </div>

                            <div class="submit-qual-section-block divider-line">
                                <div class="headline"><span class="required">*</span>所有学历《教育部学历证书电子注册备案表》</div>
                                <div class="tips">要求：从学信网下载PDF版且在有效期内，支持jpg, png, JPEG格式，单个文件不超过5M</div>
                                <div class="upload">
                                    <van-uploader v-model="uploadEducationName" :disabled="disabled"
                                                  :before-delete="beforeEducationNameDelete"
                                                  :before-read="beforeEducationNameRead"
                                                  :deletable="deletable" accept="image/*" max-size="5*1024*1024"
                                                  max-count="5" multiple/>
                                </div>
                            </div>

                            <div class="submit-qual-section-block divider-line">
                                <div class="headline">个人相关资格证书、职称扫描件</div>
                                <div class="tips">要求：支持jpg, png, JPEG格式，单个文件不超过5M</div>
                                <div class="upload">
                                    <van-uploader v-model="uploadPersonalName" :disabled="disabled"
                                                  :before-delete="beforePersonalNameDelete"
                                                  :before-read="beforePersonalNameRead"
                                                  :deletable="deletable" accept="image/*" max-size="5*1024*1024"
                                                  max-count="5" multiple/>
                                </div>
                            </div>

                            <div class="submit-qual-section-block divider-line">
                                <div class="headline">其他可以证明符合报名要求的相关资料</div>
                                <div class="tips">要求：支持jpg, png, JPEG格式，单个文件不超过5M</div>
                                <div class="upload">
                                    <van-uploader v-model="uploadOtherName" :disabled="disabled" :deletable="deletable"
                                                  :before-delete="beforeOtherNameDelete"
                                                  :before-read="beforeOtherNameRead"
                                                  accept="image/*" max-size="5*1024*1024" max-count="5"
                                                  multiple/>
                                </div>
                            </div>

                        </div>
                        <div class="submit-btn"
                             v-if="positionViewForm.auditResource==='未上传' && positionViewForm.statusName==='待资审'">
                            <van-button type="primary" @click="handleSubmit" square block>确认提交</van-button>
                        </div>

                    </van-form>
                </div>

            </div>
        </div>
        <div class="footer">
        </div>
    </div>
</template>

<script>
import {
    editStatus,
    getApplicantById,
    getPositionById,
    reqGetImgUrlBySourceId,
    reqUploadSignature
} from "../../api/client-api";
import {downloadFileURL, uploadFileURL} from "../../api/user-api";

export default {
    data() {
        return {
            uploadFiles: uploadFileURL,
            downloadFileURL: downloadFileURL,
            phone: '',
            positionViewForm: {},
            paid: null,
            disabled: false,
            deletable: true,
            idList: [],
            uploadIdName: [],
            uploadBareheadedName: [],
            uploadCertificateName: [],
            uploadEducationName: [],
            uploadPersonalName: [],
            uploadOtherName: [],
            deleteImg: [],
        }
    },
    methods: {
        handleSubmit() {
            let paras = {
                paid: this.paid,
                uploadIdName: this.uploadIdName,
                uploadBareheadedName: this.uploadBareheadedName,
                uploadCertificateName: this.uploadCertificateName,
                uploadEducationName: this.uploadEducationName,
                uploadPersonalName: this.uploadPersonalName,
                uploadOtherName: this.uploadOtherName,
                deleteImg: this.deleteImg
            }
            if (this.uploadIdName.length < 2) {
                this.$notify({type: 'danger', duration: '1000', message: '请上传身份证扫描件正反面'});
            } else if (this.uploadBareheadedName.length < 1) {
                this.$notify({type: 'danger', duration: '1000', message: '请上传近期一寸免冠电子证件照片'});
            } else if (this.uploadCertificateName.length < 1) {
                this.$notify({type: 'danger', duration: '1000', message: '请上传所有学历，学位证书扫描件'});
            } else if (this.uploadEducationName.length < 1) {
                this.$notify({type: 'danger', duration: '1000', message: '请上传所有学历《教育部学历证书电子注册备案表》'});
            } else {
                editStatus(paras).then((res) => {
                    if (res.data.code === 1) {
                        this.$router.push({
                            name: 'myApplication',
                            query: {projectCode: this.positionViewForm.projectCode}
                        });
                    }
                }).catch(() => {
                    console.log("err")
                })
            }
        },
        getProject() {
            reqGetImgUrlBySourceId({id: this.paid}).then((res) => {
                res.data.data.forEach((item) => {
                    if (item.category === '身份证') {
                        this.uploadIdName.push({
                            url: downloadFileURL + item.currentName + '/',
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                        this.idList.push({
                            url: downloadFileURL + item.currentName + '/',
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                    } else if (item.category === '免冠照') {
                        this.uploadBareheadedName.push({
                            url: downloadFileURL + item.currentName + '/',
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                    } else if (item.category === '证书') {
                        this.uploadCertificateName.push({
                            url: downloadFileURL + item.currentName + '/',
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                    } else if (item.category === '学历') {
                        this.uploadEducationName.push({
                            url: downloadFileURL + item.currentName + '/',
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                    } else if (item.category === '个人相关') {
                        this.uploadPersonalName.push({
                            url: downloadFileURL + item.currentName + '/',
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                    } else if (item.category === '其他') {
                        this.uploadOtherName.push({
                            url: downloadFileURL + item.currentName + '/',
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                    }
                })
            })
            getPositionById({id: this.paid}).then((res) => {
                if (res.data.code === 1 && res.data.data !== null) {
                    this.positionViewForm = res.data.data
                    this.headerTitle = this.positionViewForm.companyName + this.positionViewForm.positionName
                    getApplicantById({id: this.positionViewForm.applicantId}).then((res) => {
                        if (res.data.data.phone !== this.phone || (this.positionViewForm.auditResource === '未上传' && this.positionViewForm.statusName != '待资审')) {
                            this.$router.push({name: 'submitQualifPrompt', query: {id: this.paid}});
                        }
                    })
                } else {
                    this.$router.push({name: 'submitQualifPrompt', query: {id: this.paid}});
                }
            })
        },
        beforeIdNameRead(index) {
            if (index.length !== undefined) {
                for (let i = 0; i < index.length; i++) {
                    if (1 >= i) {
                        if (index[i].size > 5 * 1024 * 1024) {
                            this.$notify({type: 'danger', duration: '1000', message: '文件内容不得超过5M'});
                        } else {
                            const formData = new FormData();
                            formData.append("file", index[i], Date.now() + '.jpeg')
                            reqUploadSignature(formData).then((res) => {
                                if (res.data.code === 1) {
                                    res.data.data.url = downloadFileURL + res.data.data.currentName + '/',
                                        this.uploadIdName.push(res.data.data)
                                }
                            })
                        }
                    } else {
                        this.$notify({type: 'danger', duration: '1000', message: '最多上传2张图片'});
                    }
                }
            } else {
                if (index.size > 5 * 1024 * 1024) {
                    this.$notify({type: 'danger', duration: '1000', message: '文件内容不得超过5M'});
                } else {
                    const formData = new FormData();
                    formData.append("file", index, Date.now() + '.jpeg')
                    reqUploadSignature(formData).then((res) => {
                        if (res.data.code === 1) {
                            res.data.data.url = downloadFileURL + res.data.data.currentName + '/',
                                this.uploadIdName.push(res.data.data)
                        }
                    })
                }
            }
        },
        beforeIdNameDelete(index) {
            for (let i = 0; i < this.uploadIdName.length; i++) {
                if (this.uploadIdName[i].newFileName === index.newFileName) {
                    this.deleteImg.push(index.newFileName)
                    this.uploadIdName.splice(i, 1)
                }
            }
        },
        beforeBareheadedNameRead(index) {
            if (index.length !== undefined) {
                for (let i = 0; i < index.length; i++) {
                    if (0 >= i) {
                        if (index[i].size > 5 * 1024 * 1024) {
                            this.$notify({type: 'danger', duration: '1000', message: '文件内容不得超过5M'});
                        } else {
                            const formData = new FormData();
                            formData.append("file", index[i], Date.now() + '.jpeg')
                            reqUploadSignature(formData).then((res) => {
                                if (res.data.code === 1) {
                                    res.data.data.url = downloadFileURL + res.data.data.currentName + '/',
                                        this.uploadBareheadedName.push(res.data.data)
                                }
                            })
                        }
                    } else {
                        this.$notify({type: 'danger', duration: '1000', message: '最多上传1张图片'});
                    }
                }
            } else {
                if (index.size > 5 * 1024 * 1024) {
                    this.$notify({type: 'danger', duration: '1000', message: '文件内容不得超过5M'});
                } else {
                    const formData = new FormData();
                    formData.append("file", index, Date.now() + '.jpeg')
                    reqUploadSignature(formData).then((res) => {
                        if (res.data.code === 1) {
                            res.data.data.url = downloadFileURL + res.data.data.currentName + '/',
                                this.uploadBareheadedName.push(res.data.data)
                        }
                    })
                }
            }
        },
        beforeBareheadedNameDelete(index) {
            for (let i = 0; i < this.uploadBareheadedName.length; i++) {
                if (this.uploadBareheadedName[i].newFileName === index.newFileName) {
                    this.deleteImg.push(index.newFileName)
                    this.uploadBareheadedName.splice(i, 1)
                }
            }
        },
        beforeCertificateNameRead(index) {
            if (index.length !== undefined) {
                for (let i = 0; i < index.length; i++) {
                    if (4 >= i) {
                        if (index[i].size > 5 * 1024 * 1024) {
                            this.$notify({type: 'danger', duration: '1000', message: '文件内容不得超过5M'});
                        } else {
                            const formData = new FormData();
                            formData.append("file", index[i], Date.now() + '.jpeg')
                            reqUploadSignature(formData).then((res) => {
                                if (res.data.code === 1) {
                                    res.data.data.url = downloadFileURL + res.data.data.currentName + '/',
                                        this.uploadCertificateName.push(res.data.data)
                                }
                            })
                        }
                    } else {
                        this.$notify({type: 'danger', duration: '1000', message: '最多上传5张图片'});
                    }
                }
            } else {
                if (index.size > 5 * 1024 * 1024) {
                    this.$notify({type: 'danger', duration: '1000', message: '文件内容不得超过5M'});
                } else {
                    const formData = new FormData();
                    formData.append("file", index, Date.now() + '.jpeg')
                    reqUploadSignature(formData).then((res) => {
                        if (res.data.code === 1) {
                            res.data.data.url = downloadFileURL + res.data.data.currentName + '/',
                                this.uploadCertificateName.push(res.data.data)
                        }
                    })
                }
            }
        },
        beforeCertificateNameDelete(index) {
            for (let i = 0; i < this.uploadCertificateName.length; i++) {
                if (this.uploadCertificateName[i].newFileName === index.newFileName) {
                    this.deleteImg.push(index.newFileName)
                    this.uploadCertificateName.splice(i, 1)
                }
            }
        },
        beforeEducationNameRead(index) {
            if (index.length !== undefined) {
                for (let i = 0; i < index.length; i++) {
                    if (4 >= i) {
                        if (index[i].size > 5 * 1024 * 1024) {
                            this.$notify({type: 'danger', duration: '1000', message: '文件内容不得超过5M'});
                        } else {
                            const formData = new FormData();
                            formData.append("file", index[i], Date.now() + '.jpeg')
                            reqUploadSignature(formData).then((res) => {
                                if (res.data.code === 1) {
                                    res.data.data.url = downloadFileURL + res.data.data.currentName + '/',
                                        this.uploadEducationName.push(res.data.data)
                                }
                            })
                        }
                    } else {
                        this.$notify({type: 'danger', duration: '1000', message: '最多上传5张图片'});
                    }
                }
            } else {
                if (index.size > 5 * 1024 * 1024) {
                    this.$notify({type: 'danger', duration: '1000', message: '文件内容不得超过5M'});
                } else {
                    const formData = new FormData();
                    formData.append("file", index, Date.now() + '.jpeg')
                    reqUploadSignature(formData).then((res) => {
                        if (res.data.code === 1) {
                            res.data.data.url = downloadFileURL + res.data.data.currentName + '/',
                                this.uploadEducationName.push(res.data.data)
                        }
                    })
                }
            }
        },
        beforeEducationNameDelete(index) {
            for (let i = 0; i < this.uploadEducationName.length; i++) {
                if (this.uploadEducationName[i].newFileName === index.newFileName) {
                    this.deleteImg.push(index.newFileName)
                    this.uploadEducationName.splice(i, 1)
                }
            }
        },
        beforePersonalNameRead(index) {
            if (index.length !== undefined) {
                for (let i = 0; i < index.length; i++) {
                    if (4 >= i) {
                        if (index[i].size > 5 * 1024 * 1024) {
                            this.$notify({type: 'danger', duration: '1000', message: '文件内容不得超过5M'});
                        } else {
                            const formData = new FormData();
                            formData.append("file", index[i], Date.now() + '.jpeg')
                            reqUploadSignature(formData).then((res) => {
                                if (res.data.code === 1) {
                                    res.data.data.url = downloadFileURL + res.data.data.currentName + '/',
                                        this.uploadPersonalName.push(res.data.data)
                                }
                            })
                        }
                    } else {
                        this.$notify({type: 'danger', duration: '1000', message: '最多上传5张图片'});
                    }
                }
            } else {
                if (index.size > 5 * 1024 * 1024) {
                    this.$notify({type: 'danger', duration: '1000', message: '文件内容不得超过5M'});
                } else {
                    const formData = new FormData();
                    formData.append("file", index, Date.now() + '.jpeg')
                    reqUploadSignature(formData).then((res) => {
                        if (res.data.code === 1) {
                            res.data.data.url = downloadFileURL + res.data.data.currentName + '/',
                                this.uploadPersonalName.push(res.data.data)
                        }
                    })
                }
            }
        },
        beforePersonalNameDelete(index) {
            for (let i = 0; i < this.uploadPersonalName.length; i++) {
                if (this.uploadPersonalName[i].newFileName === index.newFileName) {
                    this.deleteImg.push(index.newFileName)
                    this.uploadPersonalName.splice(i, 1)
                }
            }
        },
        beforeOtherNameRead(index) {
            if (index.length !== undefined) {
                for (let i = 0; i < index.length; i++) {
                    if (4 >= i) {
                        if (index[i].size > 5 * 1024 * 1024) {
                            this.$notify({type: 'danger', duration: '1000', message: '文件内容不得超过5M'});
                        } else {
                            const formData = new FormData();
                            formData.append("file", index[i], Date.now() + '.jpeg')
                            reqUploadSignature(formData).then((res) => {
                                if (res.data.code === 1) {
                                    res.data.data.url = downloadFileURL + res.data.data.currentName + '/',
                                        this.uploadOtherName.push(res.data.data)
                                }
                            })
                        }
                    } else {
                        this.$notify({type: 'danger', duration: '1000', message: '最多上传5张图片'});
                    }
                }
            } else {
                if (index.size > 5 * 1024 * 1024) {
                    this.$notify({type: 'danger', duration: '1000', message: '文件内容不得超过5M'});
                } else {
                    const formData = new FormData();
                    formData.append("file", index, Date.now() + '.jpeg')
                    reqUploadSignature(formData).then((res) => {
                        if (res.data.code === 1) {
                            res.data.data.url = downloadFileURL + res.data.data.currentName + '/',
                                this.uploadOtherName.push(res.data.data)
                        }
                    })
                }
            }
        },
        beforeOtherNameDelete(index) {
            for (let i = 0; i < this.uploadOtherName.length; i++) {
                if (this.uploadOtherName[i].newFileName === index.newFileName) {
                    this.deleteImg.push(index.newFileName)
                    this.uploadOtherName.splice(i, 1)
                }
            }
        },
        goBack() {
            this.$router.push({name: 'myApplication', query: {projectCode: this.positionViewForm.projectCode}});
        },
    },
    mounted() {
        this.phone = sessionStorage.getItem('un')
        let token = sessionStorage.getItem('Authorization')
        this.header = {Authorization: token}
        this.paid = this.$route.params.id;
        this.getProject();
    }
};
</script>

<style scoped>

</style>