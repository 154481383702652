<template>
    <div class="wrapper">
        <Header :code="positionViewForm.projectCode"
                :title="headerTitle"></Header>
        <div class="content">
            <div class="main-content">
                <div class="breadcrumb">
                    <Breadcrumb>
                        <BreadcrumbItem @click.native="goHome(positionViewForm.projectCode)">主页</BreadcrumbItem>
                        <BreadcrumbItem @click.native="goMyApplication">我的报名</BreadcrumbItem>
                        <BreadcrumbItem>提交资质</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div class="submit-qual">
                    <Form ref="viewForm" label-colon :label-width="80">
                        <div class="divider">报名信息</div>
                        <Row :gutter="16">
                            <Col span="12">
                                <FormItem label="职位">{{ positionViewForm.positionName }}</FormItem>
                            </Col>
                            <Col span="12">
                                <FormItem label="部门"
                                          v-if="positionViewForm.deptName != '' && positionViewForm.deptName != null">
                                    {{ positionViewForm.deptName }}
                                </FormItem>
                            </Col>
                        </Row>
                        <div class="divider">资质信息</div>
                        <div class="submit-qual-section">
                            <div class="submit-qual-section-block">
                                <div class="headline"><span class="required">*</span>身份证扫描件</div>
                                <div class="tips">要求：正反面身份证件扫描件，支持jpg, JPG, png, JPEG格式，单个文件不超过5M</div>
                                <div class="upload">
                                    <div class="upload-image-list" v-for="(item, index) in IdImgUrl">
                                        <img :src="item.url" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handleView(item.url)"></Icon>
                                            <Icon
                                                v-if="positionViewForm.auditResource==='未上传' && positionViewForm.statusName==='待资审'"
                                                type="ios-trash" @click.native="handleRemove(index)"></Icon>
                                        </div>
                                    </div>
                                    <Upload
                                        v-if="uploadIdName.length < 2 &&  positionViewForm.auditResource==='未上传' && positionViewForm.statusName==='待资审'"
                                        ref="upload" multiple type="drag" :max-size="5 * 1024" :action="uploadFiles"
                                        :show-upload-list="false"
                                        :format="['jpg', 'png', 'JPG', 'JPEG']"
                                        accept=".jpg,.png,.JPG,.JPEG"
                                        :before-upload="beforeUpload"
                                        :on-success="handleSuccess"
                                        :headers="header">
                                        <div class="upload-btn-l">
                                            <Icon type="md-cloud-upload"></Icon>
                                            <p class="tips">点击上传或拖动图片到这里上传</p>
                                        </div>
                                    </Upload>
                                </div>
                            </div>

                            <div class="submit-qual-section-block divider-line">
                                <div class="headline"><span class="required">*</span>近期一寸免冠电子证件照片</div>
                                <div class="tips">要求：蓝底或白底一寸免冠电子照片，支持jpg, JPG, png, JPEG格式，单个文件不超过5M</div>
                                <div class="upload">
                                    <!-- 这里上传组件 -->
                                    <div class="upload-image-list" v-for="(item, index) in bareheadedImgUrl">
                                        <img :src="item.url" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handleView(item.url)"></Icon>
                                            <Icon
                                                v-if="positionViewForm.auditResource==='未上传' && positionViewForm.statusName==='待资审'"
                                                type="ios-trash" @click.native="handleBareheadedRemove(index)"></Icon>
                                        </div>
                                    </div>
                                    <Upload
                                        v-if="uploadBareheadedName.length < 1 && positionViewForm.auditResource==='未上传' && positionViewForm.statusName==='待资审'"
                                        ref="upload" multiple type="drag" :max-size="5 * 1024" :action="uploadFiles"
                                        :show-upload-list="false"
                                        :format="['jpg', 'png', 'JPG', 'JPEG']"
                                        accept=".jpg,.png,.JPG,.JPEG"
                                        :before-upload="beforeBareheadedImgUrlUpload"
                                        :on-success="handleBareheadedSuccess" :headers="header">
                                        <div class="upload-btn-l">
                                            <Icon type="md-cloud-upload"></Icon>
                                            <p class="tips">点击上传或拖动图片到这里上传</p>
                                        </div>
                                    </Upload>
                                </div>
                            </div>

                            <div class="submit-qual-section-block divider-line">
                                <div class="headline"><span class="required">*</span>所有学历，学位证书扫描件</div>
                                <div class="tips">要求：留学人员需上传教育部中国留学服务中心出具的国（境）外学历学位认证书，支持jpg, JPG, png, JPEG格式，单个文件不超过5M
                                </div>
                                <div class="upload">
                                    <!-- 这里上传组件 -->
                                    <div class="upload-image-list" v-for="(item, index) in certificateImgUrl">
                                        <img :src="item.url" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handleView(item.url)"></Icon>
                                            <Icon type="ios-trash"
                                                  v-if="positionViewForm.auditResource==='未上传' && positionViewForm.statusName==='待资审'"
                                                  @click.native="handleCertificateRemove(index)"></Icon>
                                        </div>
                                    </div>
                                    <Upload
                                        v-if="uploadCertificateName.length < 5 && positionViewForm.auditResource==='未上传' && positionViewForm.statusName==='待资审'"
                                        ref="upload" multiple type="drag" :max-size="5 * 1024" :action="uploadFiles"
                                        :show-upload-list="false"
                                        :format="['jpg', 'png', 'JPG', 'JPEG']"
                                        accept=".jpg,.png,.JPG,.JPEG"
                                        :before-upload="beforeCertificateImgUrlUpload"
                                        :on-success="handleCertificateSuccess"
                                        :headers="header">
                                        <div class="upload-btn-l">
                                            <Icon type="md-cloud-upload"></Icon>
                                            <p class="tips">点击上传或拖动图片到这里上传</p>
                                        </div>
                                    </Upload>
                                </div>
                            </div>

                            <div class="submit-qual-section-block divider-line">
                                <div class="headline"><span class="required">*</span>所有学历《教育部学历证书电子注册备案表》</div>
                                <div class="tips">要求：从学信网下载PDF版且在有效期内，支持jpg, JPG, png, JPEG格式，单个文件不超过5M</div>
                                <div class="upload">
                                    <!-- 这里上传组件 -->
                                    <div class="upload-image-list" v-for="(item, index) in educationImgUrl">
                                        <img :src="item.url" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handleView(item.url)"></Icon>
                                            <Icon
                                                v-if="positionViewForm.auditResource==='未上传' && positionViewForm.statusName==='待资审'"
                                                type="ios-trash" @click.native="handleEducationRemove(index)"></Icon>
                                        </div>
                                    </div>
                                    <Upload
                                        v-if="uploadEducationName.length < 5 && positionViewForm.auditResource==='未上传' && positionViewForm.statusName==='待资审'"
                                        ref="upload" multiple type="drag" :max-size="5 * 1024" :action="uploadFiles"
                                        :show-upload-list="false"
                                        :format="['jpg', 'png', 'JPG', 'JPEG']"
                                        accept=".jpg,.png,.JPG,.JPEG"
                                        :before-upload="beforeEducationImgUrlUpload"
                                        :on-success="handleEducationSuccess" :headers="header">
                                        <div class="upload-btn-l">
                                            <Icon type="md-cloud-upload"></Icon>
                                            <p class="tips">点击上传或拖动图片到这里上传</p>
                                        </div>
                                    </Upload>
                                </div>
                            </div>

                            <div class="submit-qual-section-block divider-line">
                                <div class="headline">个人相关资格证书、职称扫描件</div>
                                <div class="tips">要求：支持jpg, JPG, png, JPEG格式，单个文件不超过5M</div>
                                <div class="upload">
                                    <!-- 这里上传组件 -->
                                    <div class="upload-image-list" v-for="(item, index) in personalImgUrl">
                                        <img :src="item.url" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handleView(item.url)"></Icon>
                                            <Icon
                                                v-if="positionViewForm.auditResource==='未上传' && positionViewForm.statusName==='待资审'"
                                                type="ios-trash" @click.native="handlePersonalRemove(index)"></Icon>
                                        </div>
                                    </div>
                                    <Upload
                                        v-if="uploadPersonalName.length < 5 && positionViewForm.auditResource==='未上传' && positionViewForm.statusName==='待资审'"
                                        ref="upload" multiple type="drag" :max-size="5 * 1024" :action="uploadFiles"
                                        :show-upload-list="false"
                                        :format="['jpg', 'png', 'JPG', 'JPEG']"
                                        accept=".jpg,.png,.JPG,.JPEG"
                                        :before-upload="beforePersonalImgUrlUpload"
                                        :on-success="handlePersonalSuccess"
                                        :headers="header">
                                        <div class="upload-btn-l">
                                            <Icon type="md-cloud-upload"></Icon>
                                            <p class="tips">点击上传或拖动图片到这里上传</p>
                                        </div>
                                    </Upload>
                                </div>
                            </div>

                            <div class="submit-qual-section-block divider-line">
                                <div class="headline">其他可以证明符合报名要求的相关资料</div>
                                <div class="tips">要求：支持jpg, JPG, png, JPEG格式，单个文件不超过5M</div>
                                <div class="upload">
                                    <!-- 这里上传组件 -->
                                    <div class="upload-image-list" v-for="(item, index) in otherImgUrl">
                                        <img :src="item.url" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handleView(item.url)"></Icon>
                                            <Icon
                                                v-if="positionViewForm.auditResource==='未上传' && positionViewForm.statusName==='待资审'"
                                                type="ios-trash" @click.native="handleOtherRemove(index)"></Icon>
                                        </div>
                                    </div>
                                    <Upload
                                        v-if="uploadOtherName.length < 5 && positionViewForm.auditResource==='未上传' && positionViewForm.statusName==='待资审'"
                                        ref="upload" multiple type="drag" :max-size="5 * 1024" :action="uploadFiles"
                                        :show-upload-list="false"
                                        :format="['jpg', 'png', 'JPG', 'JPEG']"
                                        accept=".jpg,.png,.JPG,.JPEG" :before-upload="beforeOtherImgUrlUpload"
                                        :on-success="handleOtherSuccess"
                                        :headers="header">
                                        <div class="upload-btn-l">
                                            <Icon type="md-cloud-upload"></Icon>
                                            <p class="tips">点击上传或拖动图片到这里上传</p>
                                        </div>
                                    </Upload>
                                </div>
                            </div>

                        </div>
                        <Modal class-name="view_image_modal vertical-center-modal" v-model="showPreview" footer-hide
                               :width="640">
                            <img :src="imageUrl" style="width: 100%"/>
                        </Modal>
                        <div class="submit-btn">
                            <Button v-if="positionViewForm.auditResource==='未上传' && positionViewForm.statusName==='待资审'"
                                    size="large" type="primary" shape="circle" @click="handleSubmit">确认提交
                            </Button>
                        </div>

                    </Form>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "../../components/pc/Header";
import Footer from "../../components/pc/Footer"
import {
    editStatus,
    getApplicantById,
    getPositionById,
    reqGetImgUrlBySourceId
} from "../../api/client-api";
import {uploadFileURL, downloadFileURL} from "../../api/user-api";

export default {
    components: {
        Header: Header,
        Footer: Footer
    },
    data() {
        return {
            headerTitle: '',
            phone: '',
            positionViewForm: {},
            paid: null,
            uploadFiles: uploadFileURL,
            downloadFileURL: downloadFileURL,
            header: {},
            copyImage: [],
            bareheadedCopyImage: [],
            certificateCopyImage: [],
            educationCopyImage: [],
            personalCopyImage: [],
            otherCopyImage: [],
            uploadIdName: [],
            uploadBareheadedName: [],
            uploadCertificateName: [],
            uploadEducationName: [],
            uploadPersonalName: [],
            uploadOtherName: [],
            IdImgUrl: [],
            bareheadedImgUrl: [],
            certificateImgUrl: [],
            educationImgUrl: [],
            personalImgUrl: [],
            otherImgUrl: [],
            imageUrl: '',
            showPreview: false,
            deleteImg: [],
        }
    },
    methods: {
        getProject() {
            reqGetImgUrlBySourceId({id: this.paid}).then((res) => {
                res.data.data.forEach((item) => {
                    if (item.category === '身份证') {
                        this.IdImgUrl.push({url: downloadFileURL + item.currentName + '/'})
                        this.uploadIdName.push({
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                        this.copyImage.push({
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                    } else if (item.category === '免冠照') {
                        this.bareheadedImgUrl.push({url: downloadFileURL + item.currentName + '/'})
                        this.uploadBareheadedName.push({
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                        this.bareheadedCopyImage.push({
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                    } else if (item.category === '证书') {
                        this.certificateImgUrl.push({url: downloadFileURL + item.currentName + '/'})
                        this.uploadCertificateName.push({
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                        this.certificateCopyImage.push({
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                    } else if (item.category === '学历') {
                        this.educationImgUrl.push({url: downloadFileURL + item.currentName + '/'})
                        this.uploadEducationName.push({
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                        this.educationCopyImage.push({
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                    } else if (item.category === '个人相关') {
                        this.personalImgUrl.push({url: downloadFileURL + item.currentName + '/'})
                        this.uploadPersonalName.push({
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                        this.personalCopyImage.push({
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                    } else if (item.category === '其他') {
                        this.otherImgUrl.push({url: downloadFileURL + item.currentName + '/'})
                        this.uploadOtherName.push({
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                        this.otherCopyImage.push({
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                    }
                })
            })
            getPositionById({id: this.paid}).then((res) => {
                if (res.data.code === 1 && res.data.data !== null) {
                    this.positionViewForm = res.data.data
                    this.headerTitle = this.positionViewForm.companyName + this.positionViewForm.positionName
                    getApplicantById({id: this.positionViewForm.applicantId}).then((res) => {
                        if (res.data.data.phone !== this.phone || (this.positionViewForm.auditResource==='未上传' && this.positionViewForm.statusName !='待资审')) {
                            this.$router.push({name: 'submitQualifPrompt', query: {id: this.paid}});
                        }
                    })
                } else {
                    this.$router.push({name: 'submitQualifPrompt', query: {id: this.paid}});
                }
            })
        },
        goHome(code) {
            this.$router.push({name: 'project', params: {id: code}});
        },
        goMyApplication() {
            this.$router.push({name: 'myApplication', query: {projectCode: this.positionViewForm.projectCode}});
        },
        handleSubmit() {
            this.$refs['viewForm'].validate((valid) => {
                if (valid) {
                    this.loading = true
                    let paras = {
                        paid: this.paid,
                        uploadIdName: this.uploadIdName,
                        uploadBareheadedName: this.uploadBareheadedName,
                        uploadCertificateName: this.uploadCertificateName,
                        uploadEducationName: this.uploadEducationName,
                        uploadPersonalName: this.uploadPersonalName,
                        uploadOtherName: this.uploadOtherName,
                        deleteImg: this.deleteImg
                    }
                    if (this.uploadIdName.length < 2) {
                        this.$Message.warning("请上传身份证扫描件正反面")
                    } else if (this.uploadBareheadedName.length < 1) {
                        this.$Message.warning("请上传近期一寸免冠电子证件照片")
                    } else if (this.uploadCertificateName.length < 1) {
                        this.$Message.warning("请上传所有学历，学位证书扫描件")
                    } else if (this.uploadEducationName.length < 1) {
                        this.$Message.warning("请上传所有学历《教育部学历证书电子注册备案表》")
                    } else {
                        editStatus(paras).then((res) => {
                            if (res.data.code === 1) {
                                this.$router.push({
                                    name: 'myApplication',
                                    query: {projectCode: this.positionViewForm.projectCode}
                                });
                            }
                        }).catch(() => {
                            console.log("err")
                        })
                    }
                }
            })
        },
        beforeUpload(file) {
            if (file.size <= 5 * 1024 * 1024) {
                this.copyImage.push(file)
                const check = this.copyImage.length <= 2
                if (!check) {
                    this.$Message.warning('最多上传2张图片')
                    return check
                }

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const _base64 = reader.result;
                    this.IdImgUrl.push({url: _base64, name: file.name});
                }
            } else {
                this.$Message.error('文件内容不得超过5M')
            }
        },
        beforeBareheadedImgUrlUpload(file) {
            if (file.size <= 5 * 1024 * 1024) {
                this.bareheadedCopyImage.push(file)
                const check = this.bareheadedCopyImage.length <= 1
                if (!check) {
                    this.$Message.warning('最多上传1张图片')
                    return check
                }

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const _base64 = reader.result;
                    this.bareheadedImgUrl.push({url: _base64, name: file.name});
                }
            } else {
                this.$Message.error('文件内容不得超过5M')
            }
        },
        beforeCertificateImgUrlUpload(file) {
            if (file.size <= 5 * 1024 * 1024) {
                this.certificateCopyImage.push(file)
                const check = this.certificateCopyImage.length <= 5
                if (!check) {
                    this.$Message.warning('最多上传5张图片')
                    return check
                }
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const _base64 = reader.result;
                    this.certificateImgUrl.push({url: _base64, name: file.name});
                }
            } else {
                this.$Message.error('文件内容不得超过5M')
            }
        },
        beforeEducationImgUrlUpload(file) {
            if (file.size <= 5 * 1024 * 1024) {
                this.educationCopyImage.push(file)
                const check = this.educationCopyImage.length <= 5
                if (!check) {
                    this.$Message.warning('最多上传5张图片')
                    return check
                }

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const _base64 = reader.result;
                    this.educationImgUrl.push({url: _base64, name: file.name});
                }
            } else {
                this.$Message.error('文件内容不得超过5M')
            }
        },
        beforePersonalImgUrlUpload(file) {
            if (file.size <= 5 * 1024 * 1024) {
                this.personalCopyImage.push(file)
                const check = this.personalCopyImage.length <= 5
                if (!check) {
                    this.$Message.warning('最多上传5张图片')
                    return check
                }

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const _base64 = reader.result;
                    this.personalImgUrl.push({url: _base64, name: file.name});
                }
            } else {
                this.$Message.error('文件内容不得超过5M')
            }
        },
        beforeOtherImgUrlUpload(file) {
            if (file.size <= 5 * 1024 * 1024) {
                this.otherCopyImage.push(file)
                const check = this.otherCopyImage.length <= 5
                if (!check) {
                    this.$Message.warning('最多上传5张图片')
                    return check
                }

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const _base64 = reader.result;
                    this.otherImgUrl.push({url: _base64, name: file.name});
                }
            } else {
                this.$Message.error('文件内容不得超过5M')
            }
        },
        //上传成功
        handleSuccess(res, file) {
            if (file.size <= 5 * 1024 * 1024) {
                if (res.code === 1) {
                    this.uploadIdName.push(res.data)
                    this.$Message.success('上传成功')
                } else {
                    this.$Message.error(res.data)
                }
            }
        },
        handleBareheadedSuccess(res, file) {
            if (file.size <= 5 * 1024 * 1024) {
                if (res.code === 1) {
                    this.uploadBareheadedName.push(res.data)
                    this.$Message.success('上传成功')
                } else {
                    this.$Message.error(res.data)
                }
            }
        },
        handleCertificateSuccess(res, file) {
            if (file.size <= 5 * 1024 * 1024) {
                if (res.code === 1) {
                    this.uploadCertificateName.push(res.data)
                    this.$Message.success('上传成功')
                } else {
                    this.$Message.error(res.data)
                }
            }
        },
        handleEducationSuccess(res, file) {
            if (file.size <= 5 * 1024 * 1024) {
                if (res.code === 1) {
                    this.uploadEducationName.push(res.data)
                    this.$Message.success('上传成功')
                } else {
                    this.$Message.error(res.data)
                }
            }
        },
        handlePersonalSuccess(res, file) {
            if (file.size <= 5 * 1024 * 1024) {
                if (res.code === 1) {
                    this.uploadPersonalName.push(res.data)
                    this.$Message.success('上传成功')
                } else {
                    this.$Message.error(res.data)
                }
            }
        },
        handleOtherSuccess(res, file) {
            if (file.size <= 5 * 1024 * 1024) {
                if (res.code === 1) {
                    this.uploadOtherName.push(res.data)
                    this.$Message.success('上传成功')
                } else {
                    this.$Message.error(res.data)
                }
            }
        },
        handleView(url) {
            this.imageUrl = url
            this.showPreview = true
        },
        handleRemove(index) {
            this.deleteImg.push(this.uploadIdName[index].newFileName)
            this.IdImgUrl.splice(index, 1)
            this.copyImage.splice(index, 1)
            this.uploadIdName.splice(index, 1)
        },
        handleBareheadedRemove(index) {
            this.deleteImg.push(this.uploadBareheadedName[index].newFileName)
            this.bareheadedImgUrl.splice(index, 1)
            this.bareheadedCopyImage.splice(index, 1)
            this.uploadBareheadedName.splice(index, 1)
        },
        handleCertificateRemove(index) {
            this.deleteImg.push(this.uploadCertificateName[index].newFileName)
            this.certificateImgUrl.splice(index, 1)
            this.certificateCopyImage.splice(index, 1)
            this.uploadCertificateName.splice(index, 1)
        },
        handleEducationRemove(index) {
            this.deleteImg.push(this.uploadEducationName[index].newFileName)
            this.educationImgUrl.splice(index, 1)
            this.educationCopyImage.splice(index, 1)
            this.uploadEducationName.splice(index, 1)
        },
        handlePersonalRemove(index) {
            this.deleteImg.push(this.uploadPersonalName[index].newFileName)
            this.personalImgUrl.splice(index, 1)
            this.personalCopyImage.splice(index, 1)
            this.uploadPersonalName.splice(index, 1)
        },
        handleOtherRemove(index) {
            this.deleteImg.push(this.uploadOtherName[index].newFileName)
            this.otherImgUrl.splice(index, 1)
            this.otherCopyImage.splice(index, 1)
            this.uploadOtherName.splice(index, 1)
        },
    },
    mounted() {
        this.phone = sessionStorage.getItem('un')
        let token = sessionStorage.getItem('Authorization')
        this.header = {Authorization: token}
        this.paid = this.$route.params.id;
        this.getProject();
    }
};
</script>

<style scoped>

</style>


